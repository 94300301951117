import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { gsap } from 'gsap'
import anime from 'animejs'
import { navigate } from 'gatsby'
import { CookieNotice } from 'gatsby-cookie-notice'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import CustomerSection from '../components/Customer/CustomerSection'
import ProcessSection from '../components/Process/ProcessSection'
import ContactFooter from '../components/Footer/ContactFooter'
import Intro from '../components/Intro/Intro'
import ScrollIndicator from '../components/Intro/ScrollIndicator'
import TmpBackground from '../components/Background/TmpBg/Background'
import StreamerSection from '../components/Streamer/StreamerSection'
import Footer from '../components/Footer/Footer'
import { useTranslation } from 'react-i18next'

gsap.registerPlugin(ScrollTrigger)

const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  overflow: hidden;
  max-width: 100%;
  z-index: 99;
`

const CookieNoticeWrapper = styled.div``

const OuterWrapper = styled.div``

const StreamerWrapper = styled.div`
  width: auto;
  min-height: 830px;
`

const ContactWrapper = styled.div`
  margin: 200px auto 0 auto;
  max-width: 1200px;
  @media (max-width: 600px) {
    margin: 50px 10% 0 10%;
  }
`

const FooterWrapper = styled.div`
  padding-top: 50px;
`

const Talente = (props) => {
  const streamerTrigger = useRef()
  const customerTrigger = useRef()
  const [streamerData, setStreamerData] = useState([])
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation('')

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('https://payload.odimm.one/api/creators?limit=200', {
        method: 'GET',
        headers: {
          accept: 'application/json',
        },
      })
      const json = await response.json()
      setStreamerData(json)
      setLoading(false)
    }
    try {
      fetchData()
    } catch (e) {
      console.log(e)
    }
    console.log(streamerTrigger)
  }, [])

  return (
    <>
      <CookieNotice
        personalizeButtonClasses={'personalize-button'}
        personalizeButtonText={'Individuelle Datenschutzeinstellungen'}
        backgroundWrapperClasses={'cookie-notice-wrapper bg-cookie'}
        declineButton={false}
        acceptButtonText={'Alle akzeptieren'}
        cookies={[
          {
            name: 'necessary',
            editable: false,
            default: true,
            title: 'Essential',
            text: 'Essential cookies are necessary for the proper functioning of the site. The site cannot function properly without them.',
          },
          {
            name: 'gatsby-gdpr-google-analytics',
            editable: true,
            default: true,
            title: 'Google Analytics',
            text: 'Google Analytics is a statistical tool of Google allowing to measure the audience of the website.',
          },
        ]}
      >
        <h4>Datenschutzeinstellungen</h4>
        <p>
          Wir nutzen Cookies auf unserer Website. <br />
          Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern. Wir verwenden Cookies
          und andere Technologien auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre
          Erfahrung zu verbessern. Personenbezogene Daten können verarbeitet werden (z. B. IP-Adressen), z. B. für personalisierte Anzeigen
          und Inhalte oder Anzeigen- und Inhaltsmessung.
          <p>Weitere Informationen über die Verwendung Ihrer Daten finden Sie in unserer Datenschutzerklärung.</p>
          <p>Sie können Ihre Auswahl jederzeit unter Einstellungen widerrufen oder anpassen.</p>
          <p>
            Wenn Sie unter 16 Jahre alt sind und Ihre Zustimmung zu freiwilligen Diensten geben möchten, müssen Sie Ihre
            Erziehungsberechtigten um Erlaubnis bitten.
          </p>
        </p>
      </CookieNotice>
      <ScrollIndicator />
      <OuterWrapper>
        <TmpBackground firstTriggerRef={streamerTrigger} secondTriggerRef={customerTrigger} />
        <ContentWrapper>
          <Intro />
          <StreamerWrapper ref={streamerTrigger} gird="grid" id="creators">
            {!loading && (
              <StreamerSection
                // id="talents"
                // streamerTrigger={streamerTrigger}
                data={streamerData}
                setData={setStreamerData}
                loading={loading}
                mode={props.mode}
                setMode={props.setMode}
              />
            )}
          </StreamerWrapper>
          <CustomerSection id="customers" />
          <ProcessSection id="process" />
          <ContactWrapper ref={customerTrigger}>
            <ContactFooter variant={'light'} />
          </ContactWrapper>
          <FooterWrapper>
            <Footer />
          </FooterWrapper>
        </ContentWrapper>
      </OuterWrapper>
    </>
  )
}

export default Talente
